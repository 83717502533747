<template>
    <div class="d-flex mb-8 relative">
        <div class="ml-4" style="width: calc(100% - 100px);">
            <div v-if="!editMode">{{ item.content }}</div>
            <v-textarea v-model="item.content" v-if="editMode" outlined dense :auto-grow="true" no-resize :rows="2"></v-textarea>
            <div>
                <small>
                - {{ item.user.first_name }}
                {{ item.user.last_name }} on
                {{
                    item.created_at | moment("dddd MMM D, YYYY")
                }}</small
                >
            </div>
        </div>
        
        <div>
            <v-btn class="edit-item" icon @click="saveText(item); editMode = !editMode;" :title="editMode ? 'Save' : 'Edit'" :color="editMode ? 'primary' : 'default'">
                <v-icon v-if="!editMode">mdi-pencil</v-icon>
                <v-icon v-if="editMode">mdi-content-save</v-icon>
            </v-btn>
        </div>
        
        <div>
            <v-btn class="delete-item" icon @click="showDeleteDialog(item.id)" title="Delete">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </div>
    </div>
</template>


<script>
export default {
    name: "NotesRow",
    props: {
      item: Object,
    },
    data() {
        return {
            editMode: false,
        }
    },
    methods: {
        saveText(item) {
            // They clicked the save icon
            if(this.editMode === true) {
                this.$store.dispatch("notes/updateNote", {
                    id: item.id,
                    content: item.content,
                });
            }
            this.$emit("savedNoteText");
        },
        showDeleteDialog(noteID) {
            this.$emit("openDeleteDialog", noteID);
        },
    },
}
</script>


<style>
.delete-item {
  position: absolute;
  top: 5px;
  right: 5px;
}

.edit-item {
  position: absolute;
  top: 5px;
  right: 35px;
}
</style>
